import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Typography,
} from "@mui/material";

export default function DialogMultiEmpresa({
  open,
  onClose,
  fetchEmpresa,
  cuit,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      sx={{ p: 3 }}
    >
      <DialogContent>
        <Typography variant="h5" textAlign="center" id="dialog-description">
          Esta empresa ya fue cargada. ¿Desea cargarla con otra zona?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
        <Button
          onClick={() => {
            fetchEmpresa(cuit);
            onClose();
          }}
          color="primary"
        >
          No
        </Button>
        <Button onClick={onClose} color="primary" variant="contained">
          Sí
        </Button>
      </DialogActions>
    </Dialog>
  );
}
