import { Link, useLocation } from "react-router-dom";
import reduceChildRoutes from "./reduceChildRoutes";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const SidebarNavList = (props) => {
  const { pages, depth, title } = props;
  const router = useLocation();
  const currentRoute = router.pathname;
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const childRoutes = pages.reduce(
    (items, page) => reduceChildRoutes({ items, page, currentRoute, depth }),
    []
  );
  // console.log(title);
  return (
    <>
      {childRoutes.length > 1 ? (
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          sx={{
            marginBottom: 2,
            backgroundColor: "transparent",
            color: "white",
          }}
        >
          <AccordionDetails>
            <Grid container>
              <Grid item md={12}>
                <Link to="#" style={{ textDecoration: "none" }}>
                  {childRoutes}
                </Link>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ) : (
        childRoutes
      )}
    </>
  );
};

export default SidebarNavList;
