import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
  Link as MuiLink,
  Card,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { NavLink } from "react-router-dom";
import { DataGrid, esES } from "@mui/x-data-grid";
import axios from "../../../utils/axios";
import hosteo from "../../../utils/hosteo";
import { enqueueSnackbar } from "notistack";
import * as XLSX from "../../../../node_modules/xlsx/xlsx.js";
import DownloadIcon from "@mui/icons-material/Download";

export default function Aprobados() {
  const [isLoading, setIsLoading] = useState(false);
  const Divider = styled(MuiDivider)(spacing);

  const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
  const Cabecera = () => {
    return (
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Reportes de aprobados
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <MuiLink component={NavLink} to="/dashboard/home">
              Home
            </MuiLink>
            <MuiLink component={NavLink} to="/dashboard/liquidaciones">
              Liquidaciones{" "}
            </MuiLink>
            <MuiLink component={NavLink} to="/dashboard/liquidaciones/reportes">
              Reportes de P.M.I.
            </MuiLink>
            <Typography>Aprobados</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
    );
  };

  const columns = [
    {
      field: "nombre_madre",
      headerName: "Nombre de la Madre",
      flex: 1,
      valueFormatter: (params) => (params.value ? params.value : "-"),
    },
    {
      field: "ndoc",
      headerName: "N° Documento",
      minWidth: 130,
      flex: 1,
      valueFormatter: (params) => (params.value ? params.value : "-"),
    },
    {
      field: "edad",
      headerName: "Edad",
      minWidth: 130,
      flex: 1,
      valueFormatter: (params) => (params.value ? params.value : "-"),
    },
    {
      field: "tiempo_gest",
      headerName: "Tiempo de Gestación (meses)",
      minWidth: 130,
      flex: 1,
      valueFormatter: (params) => (params.value ? params.value : "-"),
    },
    {
      field: "ultima_menst",
      headerName: "Última Menstruación",
      minWidth: 130,
      flex: 1,
      valueFormatter: (params) => (params.value ? params.value : "-"),
    },
    {
      field: "fec_prop_parto",
      headerName: "Fecha Probable de Parto",
      minWidth: 130,
      flex: 1,
      valueFormatter: (params) => (params.value ? params.value : "-"),
    },
    {
      field: "fechaCarga",
      headerName: "Fecha de Carga",
      minWidth: 130,
      flex: 1,
      valueFormatter: (params) => (params.value ? params.value : "-"),
    },
    {
      field: "tipo",
      headerName: "Tipo",
      minWidth: 130,
      flex: 1,
      valueFormatter: (params) => (params.value ? params.value : "-"),
      renderCell: (params) =>
        params.value === 1 ? "Recien Nacido" : "Embarazada",
    },
    {
      field: "fnac",
      headerName: "Fecha de Nacimiento",
      minWidth: 130,
      flex: 1,
      valueFormatter: (params) => (params.value ? params.value : "-"),
    },
    {
      field: "lugar",
      headerName: "Lugar de Nacimiento",
      minWidth: 130,
      flex: 1,
      valueFormatter: (params) => (params.value ? params.value : "-"),
    },
    {
      field: "peso",
      headerName: "Peso (kg)",
      minWidth: 130,
      flex: 1,
      valueFormatter: (params) => (params.value ? params.value : "-"),
    },
    {
      field: "nacimiento",
      headerName: "Nacimiento",
      minWidth: 130,
      flex: 1,
      valueFormatter: (params) => (params.value ? params.value : "-"),
      renderCell: (params) =>
        params.value === "1" ? "Parto Normal" : "Cesarea",
    },
    {
      field: "sexo",
      headerName: "Sexo",
      minWidth: 130,
      flex: 1,
      valueFormatter: (params) => (params.value ? params.value : "-"),
      renderCell: (params) => (params.value === "M" ? "Masculino" : "Femenino"),
    },
  ];

  const [datos, setDatos] = useState({});

  const obtenerDatos = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${hosteo}/api/liquidaciones/reportes/getAceptados`
      );
      const nacido = response.data.solicitudesNacido || [];
      const embarazo = response.data.solicitudesEmbarazo || [];
      setDatos([...embarazo, ...nacido]);
    } catch (error) {
      enqueueSnackbar(error.message || "No tienes conexión a internet", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    obtenerDatos();
  }, []);

  const generateAndDownloadExcel = () => {
    let nombre = "Solic. P.M.I. Aprobadas";

    const workbook = XLSX.utils.book_new();

    // Crea una nueva hoja de cálculo con datos
    const dia = new Date();
    const options = {
      timeZone: "America/Buenos_Aires",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    // Encabezado original
    const encabezado = [
      [`Reporte de ${nombre}`],
      [
        `Generado a las ${dia
          .toLocaleTimeString("es-AR", options)
          .slice(0, 5)}hs del ${dia.toLocaleDateString()}`,
      ],
      [""],
    ];

    // Títulos de las columnas
    const columnTitles = columns.map((column) => column.headerName);

    // Unir encabezado original con nuevos títulos de columna
    const datosConTitulos = [...encabezado, columnTitles];

    datos.forEach((item) => {
      const rowData = columns.map((column) => item[column.field]);
      datosConTitulos.push(rowData);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(datosConTitulos);
    XLSX.utils.book_append_sheet(workbook, worksheet, nombre);
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${nombre.xlsx}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Cabecera />
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            my: 10,
          }}
        >
          <CircularProgress size={75} />
        </Box>
      ) : (
        <>
          {datos.length > 0 ? (
            <Card sx={{ mt: 2, p: 2 }}>
              <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
                <Button
                  startIcon={<DownloadIcon />}
                  color="success"
                  variant="contained"
                  onClick={generateAndDownloadExcel}
                >
                  Descargar XLSX
                </Button>
              </Box>
              <DataGrid
                sx={{ padding: 1 }}
                disableRowSelectionOnClick
                autoHeight
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                rows={datos || []}
                columns={columns}
                initialState={{
                  pagination: { paginationModel: { page: 0, pageSize: 10 } },
                }}
                pageSizeOptions={[5, 10, 25]}
              />
            </Card>
          ) : (
            <Typography variant="h2" textAlign={"center"} sx={{ mt: 2 }}>
              No hay solicitudes aprobadas
            </Typography>
          )}
        </>
      )}
    </>
  );
}
