import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Alert,
  Button,
  Card,
  Grid,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import FolderIcon from "@mui/icons-material/Folder";
import axios from "../../utils/axios";
import hosteo from "../../utils/hosteo";
import { cards } from "../../components/Empadronamiento/ArchivosAFIP/Cards";
import { mensaje } from "../../components/Empadronamiento/ArchivosAFIP/Mensaje";
import Cabecera from "../../components/Cabecera";
import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";

const itemsHeader = [
  { linkTo: "/dashboard/home", nombre: "Home" },
  { linkTo: "/dashboard/empadronamiento", nombre: "Empadronamiento" },
];

export default function ArchivosAfip() {
  const [archivos, setArchivos] = useState(Array(cards.length).fill(null));
  const [archivo, setArchivo] = useState();
  const [errores, setErrores] = useState(Array(cards.length).fill(null));
  const [extension, setExtension] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [shouldFetchLogs, setShouldFetchLogs] = useState(false);
  const [logId, setLogId] = useState();
  const [status, setStatus] = useState(Array(cards.length).fill(null)); //
  const [message, setMessage] = useState(Array(cards.length).fill(null)); //
  const [indice, setIndice] = useState(); //Array(cards.length).fill(null)
  const [loading, setLoading] = useState(Array(cards.length).fill(false));
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRefs = useRef(Array(cards.length).fill(null));

  const handleClickAttachFile = (index, card) => {
    fileInputRefs.current[index].click();
    setExtension(card.tipo);
    setIndice(index);
  };
  const handleSubmitFile = async (code) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", archivo);
      formData.append("code", code);
      const response = await axios({
        method: "post",
        url: `${hosteo}/api/empadronamiento/subidaAFIP`,
        data: formData,
      });
      enqueueSnackbar(response.data.message, {
        variant: "success",
      });
      setLogId(response.data.logsId);
      setShouldFetchLogs(true);
      //logs();
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
    setArchivo(null);
    setArchivos(Array(cards.length).fill(null));

    // const newLoading = [...loading];
    // newLoading[indice] = false;
    // setLoading(newLoading);
    setIsLoading(false);
  };

  const logs = async () => {
    try {
      const response = await axios.post(`${hosteo}/api/generic/getLogs`, {
        id: logId,
      });
      const newLoading = [...loading];
      newLoading[indice] = false;
      setIsLoading(newLoading);
      setLoading(Array(cards.length).fill(false));

      const newMessage = [...message];
      newMessage[indice] = response.data.logs;
      setMessage(newMessage);

      const newStatus = [...status];
      newStatus[indice] = response.data.logs[0].tipo;
      setStatus(newStatus);

      // Verificar si la respuesta contiene ciertos datos específicos
      if (response.data.hasFinished === true) {
        setShouldFetchLogs(false); // Detener la ejecución
        setArchivo(null);
        setArchivos(Array(cards.length).fill(null));
      }
      const resetErrores = [...errores];
      resetErrores[indice] = null;
      setErrores(resetErrores);
    } catch (err) {
      const newErrores = [...errores];
      newErrores[indice] = err.message;
      setErrores(newErrores);

      setShouldFetchLogs(false); // Detener la ejecución
      setArchivo(null);
      setArchivos(Array(cards.length).fill(null));
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (shouldFetchLogs) {
      const interval = setInterval(() => {
        logs(logId);
      }, 3000); // Ejecutar cada 5 segundos

      return () => clearInterval(interval); // Limpiar el intervalo cuando el componente se desmonte o shouldFetchLogs cambie a false
    }
    // eslint-disable-next-line
  }, [shouldFetchLogs]);

  const handleFileChange = (e, index) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop();

      const allowedExtensions = [extension?.slice(1)];

      if (!allowedExtensions.includes(fileExtension)) {
        const newErrores = [...errores];
        newErrores[index] = (
          <Alert severity="warning">
            El archivo es inválido. Debe ser un archivo con la extensión
            {extension}
          </Alert>
        );
        setErrores(newErrores);

        setTimeout(() => {
          const resetErrores = [...errores];
          resetErrores[index] = null;
          setErrores(resetErrores);
        }, 6000);
      } else {
        const newArchivos = [...archivos];
        newArchivos[index] = e.target.files?.[0].name;
        setArchivo(e.target.files?.[0]);
        setArchivos(newArchivos);
      }
    }
  };
  return (
    <>
      <Cabecera
        title="Empadronamiento"
        items={itemsHeader}
        divider={true}
        helmet="Empadronamiento"
      />
      <Grid justifyContent={"center"} display={"flex"}>
        <Grid container md={10} sm={10} justifyContent={"inherit"}>
          {cards.map((card, index) => {
            return (
              <Grid
                lg={6}
                md={6}
                sm={5}
                xs={8}
                sx={{ marginBottom: 6 }}
                key={index}
              >
                <Grid md={11} sm={11}>
                  <Card sx={{ padding: 8 }} id={index}>
                    <Grid justifyContent={"center"} display={"flex"}>
                      <Typography variant="subtitle1">{card.nombre}</Typography>
                    </Grid>
                    <Grid
                      justifyContent={"center"}
                      display={"flex"}
                      sx={{ marginTop: 3 }}
                    >
                      <div style={{ marginTop: 15 }}>
                        <Stack
                          direction="column"
                          alignItems="center"
                          spacing={1}
                        >
                          <LoadingButton
                            onClick={() => handleClickAttachFile(index, card)}
                            variant="contained"
                            disabled={isLoading}
                            loading={isLoading}
                            startIcon={<FolderIcon />}
                          >
                            Elegir archivo
                          </LoadingButton>
                          {archivos[index] !== null && (
                            <>
                              <div>
                                <Typography variant="subtitle2">
                                  {archivos[index]}
                                </Typography>
                              </div>
                              <div>
                                <Button
                                  sx={{ textAlign: "center" }}
                                  variant="contained"
                                  color="warning"
                                  disabled={isLoading === true && true}
                                  onClick={() =>
                                    handleSubmitFile(cards[index].id)
                                  }
                                  startIcon={<CloudUploadIcon />}
                                >
                                  Subir archivo
                                </Button>
                              </div>
                            </>
                          )}
                          <input
                            ref={(el) => {
                              if (el) {
                                fileInputRefs.current[index] = el;
                              }
                            }}
                            key={index}
                            type="file"
                            accept={card.tipo}
                            onChange={(event) => {
                              handleFileChange(event, index, { card });
                            }}
                            style={{ display: "none" }}
                          />
                        </Stack>
                      </div>
                    </Grid>
                    {isLoading ? (
                      <Grid marginTop={3} md={12}>
                        <Grid container>
                          <Grid item md={9} margin={"auto"}>
                            <Typography
                              textAlign={"center"}
                              sx={{
                                animation: "fadeInOut 2s ease-in-out infinite",
                                "@keyframes fadeInOut": {
                                  "0%": {
                                    opacity: 0,
                                  },
                                  "50%": {
                                    opacity: 1,
                                  },
                                  "100%": {
                                    opacity: 0,
                                  },
                                },
                                color: "#376fd0", // Ajuste de color
                                fontSize: "16px",
                              }}
                            >
                              Cargando...
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        {status[index] !== null && (
                          <>
                            <Grid marginTop={3} md={12}>
                              <Grid container mr={2}>
                                {message[index].map((card, indice) => {
                                  return (
                                    <Grid container md={12} my={2}>
                                      <Grid
                                        item
                                        lg={2.2}
                                        md={3.1}
                                        margin={"auto"}
                                      >
                                        {mensaje(message[index][indice].tipo)}
                                      </Grid>
                                      <Grid
                                        item
                                        lg={9.6}
                                        md={8}
                                        margin={"auto"}
                                      >
                                        <Typography>
                                          {message[index][indice].message}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                    {errores[index] && (
                      <Grid container md={12} mt={2}>
                        <Alert severity="error">{errores[index]}</Alert>
                      </Grid>
                    )}
                  </Card>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
        <Link to={"reporte"}>
          <Button variant="contained">Ver reportes</Button>
        </Link>
      </Box>
    </>
  );
}
