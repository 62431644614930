import {
  Autocomplete,
  Card,
  Grid,
  TextField,
  Typography,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
  Link as MuiLink,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import es from "date-fns/locale/es";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useState, useEffect, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import hosteo from "../../../utils/hosteo";
import axios from "../../../utils/axios";
import { Form, FormikProvider, useFormik, ErrorMessage } from "formik";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import CustomLabel from "../../../theme/CustomLabel";
import { fDate } from "../../../utils/formatNumber";
import * as Yup from "yup";
import WarningIcon from "@mui/icons-material/Warning";
import { DialogZona } from "../../../components/Empadronamiento/Empresas/DialogZona";
import DialogMultiEmpresa from "./DialogMultiEmpresa";

const Divider2 = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Cabecera = () => {
  return (
    <Grid justifyContent="space-between" container spacing={6}>
      <Grid item>
        <Typography variant="h3" gutterBottom>
          Alta, Baja y Modificaciones de Empresas
        </Typography>
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <MuiLink component={NavLink} to="/dashboard/home">
            Home
          </MuiLink>
          <MuiLink component={NavLink} to="/dashboard/empadronamiento">
            Empadronamiento
          </MuiLink>
          <Typography>ABM Empresas</Typography>
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
};

const zonasTipo1 = [300, 350, 400, 450, 600, 700, 800, 900];

export default function ABMEmpresas() {
  const { enqueueSnackbar } = useSnackbar();
  const [cuit, setCuit] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [provincias, setProvincias] = useState([]);
  const [localidad, setLocalidad] = useState([]);
  const [zonas, setZonas] = useState([]);
  // const [empresa, setEmpresa] = useState([]);
  const [selectedZonas, setSelectedZonas] = useState(null);
  const [selectedCodigo, setSelectedCodigo] = useState(null);
  const [feBaja, setFeBaja] = useState(null);
  const [feAlta, setFeAlta] = useState(null);
  const [selectedProv, setSelectedProv] = useState(null);
  const [selectedEspecialidad, setSelectedEspecialidad] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogZona, setOpenDialogZona] = useState(false);
  const [baja, setBaja] = useState(false);
  const [autokey, setAutoKey] = useState("Autocomplete");
  const [disabledCuit, setDisabledCuit] = useState(null);
  const [localidadAfipSelected, setLocalidadAfipSelected] = useState(null);
  const [keyZona, setKeyZona] = useState(null);

  const [openDialogMultiEmpresa, setOpenDialogMultiEmpresa] = useState(false);
  const [datosEmpresa, setDatosEmpresa] = useState(null);

  const handleCloseDialogMultiEmpresa = () => {
    setOpenDialogMultiEmpresa(false);
  };

  const handleOpenDialogMultiEmpresa = () => {
    setOpenDialogMultiEmpresa(true);
  };

  const handleOpenZona = () => {
    setOpenDialogZona(!openDialogZona);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setBaja(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // ------------------------------ Automplete de codigo de empresa --------------------------

  useEffect(() => {
    keyZona && getCodEmpresa(selectedZonas.codigo);

    //es-lint-disable-next-line
  }, [keyZona]);

  const getCodEmpresa = async (value, key) => {
    try {
      const response = await axios.post(
        `${hosteo}/api/empadronamiento/empresas/getCodEmpresa`,
        { zona: value, tipo: key ? key : keyZona }
      );
      setFieldValue(
        "c1CodEm",
        response.data.siguienteCodigo === null
          ? "1"
          : response.data.siguienteCodigo
      );
    } catch (error) {
      enqueueSnackbar(error.message || "No tienes conexión a internet", {
        variant: "error",
      });
    } finally {
      setKeyZona(null);
    }
  };
  //------------------------------
  useEffect(() => {
    const fetchProvincias = async () => {
      try {
        const response = await axios.post(
          `${hosteo}/api/generic/getProvincias`
        );
        setProvincias(response.data);
      } catch (error) {
        enqueueSnackbar(error.message || "No tienes conexión a internet", {
          variant: "error",
        });
      }
    };
    fetchProvincias();

    //es-lint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchLocalidades = async () => {
      try {
        const response = await axios.post(
          `${hosteo}/api/empadronamiento/localidades/getLocalidades`
        );
        setLocalidad(response.data);
      } catch (error) {
        enqueueSnackbar(error.message || "No tienes conexión a internet", {
          variant: "error",
        });
      }
    };
    fetchLocalidades();

    //es-lint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchZonas = async () => {
      try {
        const response = await axios.post(`${hosteo}/api/generic/getZonas`);
        setZonas(response.data);
      } catch (error) {
        enqueueSnackbar(error.message || "No tienes conexión a internet", {
          variant: "error",
        });
      }
    };
    fetchZonas();

    //es-lint-disable-next-line
  }, []);

  const fetchEmpresa = async (cuit) => {
    try {
      if (cuit.length < 11 || cuit.length > 11) {
        console.log("error");
      } else {
        const response = await axios.post(
          `${hosteo}/api/empadronamiento/empresas/getEmpresa`,
          {
            cuit: cuit,
          }
        );
        setDatosEmpresa(response.data);
        setDisabledCuit(true);
        handleOpenDialogMultiEmpresa();
      }
    } catch (error) {
      console.log(error.message);
      setDisabledCuit(false);
    }
  };
  const handleChangeEmpresa = (empresa) => {
    if (empresa) {
      formik.setValues({
        c1CodEm: empresa.codigo || "",
        c1RazSoc: empresa.razon || "",
        c1Nom: empresa.fantasia || "",
        c3Calle: empresa.calle || "",
        c3Num: empresa.numero || "",
        c3Depto: empresa.depto || "",
        c3Tel: empresa.telefono || "",
        c5Espe: empresa.especialidad || "",
        // c5Espe: empresa.especialidad || "",
        c5MotBaj: empresa.mbaja || "",
        c6Ospiv: empresa.obreros || "",
        calleAfip: empresa.calleAfip || "",
        numeroAfip: empresa.numeroAfip || "",
        pisoAfip: empresa.pisoAfip || "",
        dptoAfip: empresa.dptoAfip || "",
        pciaAfip: empresa.pciaAfip
          ? provincias.find(
              (element) => Number(element.codigo) === Number(empresa.pciaAfip)
            )
          : null,
      });
      setFieldValue(
        "c1Zona1",
        zonas.find((element) => Number(element.codigo) === Number(empresa.zona))
          .codigo
      );
      setFieldValue("c1Cuit", cuit);

      setSelectedZonas(
        zonas.find((element) => Number(element.codigo) === Number(empresa.zona))
      );
      setLocalidadAfipSelected(
        empresa.cpostalAfip
          ? localidad.find(
              (element) =>
                Number(element.codigo) === Number(empresa.cpostalAfip)
            )
          : null
      );
      setSelectedCodigo(
        localidad.find((element) => element.codigo === empresa.postal)
      );
      setSelectedProv(
        provincias.find((element) => element.codigo === empresa.cprov)
      );
      setFeBaja(
        empresa.baja === "0000-00-00" || empresa.baja === null
          ? null
          : new Date(`${empresa.baja} 00:00`)
      );
      setFeAlta(
        empresa.alta === "0000-00-00" || empresa.alta === null
          ? null
          : new Date(`${empresa.alta} 00:00`)
      );
      setSelectedEspecialidad(
        especialidades.find((element) => element.id === empresa.especialidad)
      );
    } else {
      setFeAlta(null);
      setFeBaja(null);
    }
  };

  const resetForm = () => {
    if (!disabledCuit) {
      const newCodigo = Number(formik.values.c1CodEm) + 1;
      formik.resetForm();
      setFieldValue("c1CodEm", newCodigo);
    } else {
      formik.resetForm();
    }
    setLocalidadAfipSelected(null);
    setSelectedZonas(null);
    setSelectedCodigo(null);
    setSelectedProv(null);
    setFeAlta(null);
    setFeBaja(null);
    setBaja(false);
    setSelectedEspecialidad(null);
    setCuit(null);
    setFieldValue("c1Cuit", null);
    setAutoKey(autokey === "Autocomplete" ? "Autocomplete1" : "Autocomplete");
    //----------------
    // setDisabledCuit(false);
    setDisabledCuit(null);
    setKeyZona(null);
  };

  const validationSchema = Yup.object().shape({
    c1Cuit: Yup.number("Debe ser un nummero")
      .required("Este campo es requerido")
      .max(99999999999, "El CUIL debe tener 11 digitos")
      .min(11111111111, "El CUIL debe tener 11 digitos"),
    dptoAfip: Yup.string().max(
      3,
      "El campo 'dpto' debe tener como máximo 3 caracteres"
    ),
    c1Zona1: Yup.string().required("Este campo es requerido"),
    c1CodEm: Yup.number("Debe ser un nummero")
      .required("Este campo es requerido")
      .max(9999, "No puede superar los 4 digitos"),
    numeroAfip: Yup.number().typeError("Debe ser un número"),
    c3Num: Yup.number().typeError("Debe ser un número"),
    c3Tel: Yup.number().typeError("Debe ser un número"),
    c4Tel: Yup.number().typeError("Debe ser un número"),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      c1Cuit: cuit,
      c1Zona1: selectedZonas?.codigo,
      c1Zona2: "",
      c1CodEm: "",
      c1RazSoc: "",
      c1Nom: "",
      //--------------------
      calleAfip: "",
      numeroAfip: "",
      pisoAfip: "",
      dptoAfip: "",
      localidadAfip: "",
      pciaAfip: null,
      //-------------------
      c3Calle: "",
      c3Num: "",
      c3Piso: "",
      c3Depto: "",
      c3Tel: "",
      c3CP: "",
      c3Loc: "",
      c3Prov: "",
      //-------------------
      c4Calle: "",
      c4Num: "",
      c4Piso: "",
      c4Depto: "",
      c4Tel: "",
      c4CP: "",
      c4CP2: "",
      c4Prov: "",
      c4Prov2: "",
      //-------------------
      c5FechaAl: "",
      c5Espe: "",
      c5Espe2: "",
      c5FechaBaj: "",
      c5MotBaj: "",
      //-------------------
      c6DdJj: "",
      c5RedAo: "",
      c6Pago: "",
      c6Tranfe: "",
      c6Subs: "",
      c6Ospiv: "",
      c6Afip: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        if (baja === false) {
          const response = await axios.post(
            `${hosteo}/api/empadronamiento/empresas/sendEmpresa`,
            {
              cuit,
              zona: selectedZonas?.codigo,
              codigo: values.c1CodEm,
              razon: values.c1RazSoc,
              fantasia: values.c1Nom,
              //-----------------------
              calleAfip: values.calleAfip,
              numeroAfip: values.numeroAfip,
              pisoAfip: values.pisoAfip,
              dptoAfip: values.dptoAfip,
              localidadAfip: localidadAfipSelected?.nombre,
              cpostalAfip: localidadAfipSelected?.codigo,
              pciaAfip: values.pciaAfip?.codigo,
              //-----------------------
              calle: values.c3Calle,
              numero: values.c3Num,
              piso: values.c3Piso,
              depto: values.c3Depto,
              postal: values.c3CP,
              localidad: values.c3Loc,
              cprov: values.c3Prov,
              telefono: values.c3Tel,
              //-----------------------
              alta: feAlta === null ? null : fDate(feAlta),
              baja: feBaja === null ? null : fDate(feBaja),
              obreros: values.c6Ospiv,
              especial: values.c5Espe,
              mbaja: values.c5MotBaj,
              // referencia: "",
              // horario: "",
              // email: "",
              // zonges: "",
            }
          );

          enqueueSnackbar(
            response.data.message || "Empresa guardada correctamente",
            {
              variant: "success",
            }
          );
          resetForm();
        } else {
          const response = await axios.post(
            `${hosteo}/api/empadronamiento/empresas/sendEmpresa`,
            {
              cuit,
              zona: selectedZonas?.codigo,
              codigo: values.c1CodEm,
              razon: values.c1RazSoc,
              fantasia: values.c1Nom,
              //-----------------------
              calle: values.c3Calle,
              numero: values.c3Num,
              piso: values.c3Piso,
              depto: values.c3Depto,
              postal: values.c3CP,
              localidad: values.c3Loc,
              cprov: values.c3Prov,
              telefono: values.c3Tel,
              //-----------------------
              calleAfip: values.calleAfip,
              numeroAfip: values.numeroAfip,
              pisoAfip: values.pisoAfip,
              dptoAfip: values.dptoAfip,
              localidadAfip: localidadAfipSelected?.nombre,
              cpostalAfip: localidadAfipSelected?.codigo,
              pciaAfip: values.pciaAfip?.codigo,
              //-------------
              alta: feAlta === null ? null : fDate(feAlta),
              baja: feBaja === null ? null : fDate(feBaja),
              obreros: values.c6Ospiv,
              especial: values.c5Espe,
              mbaja: values.c5MotBaj,
              novedad: "BAJA",
              // referencia: "",
              // horario: "",
              // email: "",
              // zonges: "",
            }
          );
          enqueueSnackbar(
            response.data.message || "Empresa guardada correctamente",
            {
              variant: "success",
            }
          );
          resetForm();
        }
        setBaja(false);
      } catch (error) {
        if (error.errorcode === "02") {
          handleOpenDialog();
        } else {
          enqueueSnackbar(error.message || "Algo salió mal", {
            variant: "error",
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
  });
  const { handleSubmit, getFieldProps, setFieldValue, touched, errors } =
    formik;

  const handleKeyDown = (event, nextInputId) => {
    if (event.key === "Enter") {
      if (nextInputId === 18) {
        datePickerRef.current.focus();
      } else if (nextInputId === 31) {
        datePickerRef2.current.focus();
      } else if (nextInputId === 35) {
        console.log("listo");
      } else {
        document.getElementById(nextInputId + 1).focus();
      }
    }
    console.log(nextInputId + 1);
  };
  const datePickerRef = useRef(null);
  const datePickerRef2 = useRef(null);

  const especialidades = [
    { id: "1", label: "Propio Gremio" },
    { id: "2", label: "Traspaso" },
    { id: "3", label: "Monotributista" },
  ];

  return (
    <>
      <Helmet title="Empadronamiento" />
      <Cabecera />
      <Divider2 my={2} />
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
            <Grid container sx={{ display: "flex", justifyContent: "center" }}>
              <Grid xl={8} lg={10} sm={12} xs={11}>
                <Card sx={{ padding: 3 }}>
                  <Grid
                    container
                    gap={3}
                    sx={{ display: "flex", justifyContent: "center" }}
                    marginY={3}
                  >
                    <Box sx={{ display: "flex" }}>
                      {/* {disabledCuit && (
                        <Button
                          sx={{ position: "absolute" }}
                          startIcon={<CloseIcon />}
                          onClick={resetForm}
                        />
                      )} */}
                      <TextField
                        key={autokey}
                        type="number"
                        fullWidth
                        id={1}
                        onKeyDown={(e) => handleKeyDown(e, 1)}
                        label={<CustomLabel>CUIT</CustomLabel>}
                        defaultValue={cuit}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {disabledCuit && (
                                <IconButton onClick={resetForm}>
                                  <CloseIcon />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                        onChange={(event) => {
                          setCuit(event.target.value);
                          setFieldValue("c1Cuit", event.target.value);
                        }}
                        onBlur={(event, value) => {
                          console.log(event.target.value);

                          if (event.target.value.length == 11) {
                            fetchEmpresa(event.target.value);
                          } else {
                            enqueueSnackbar("El CUIT debe tener 11 dígitos", {
                              variant: "warning",
                            });
                          }
                        }}
                        error={Boolean(touched.c1Cuit && errors.c1Cuit)}
                        helperText={touched.c1Cuit && errors.c1Cuit}
                        disabled={disabledCuit}
                      />
                    </Box>
                    <Grid container md={3} sm={3} gap={0} xs={4}>
                      <Autocomplete
                        fullWidth
                        disabled={isLoading}
                        disablePortal
                        autoHighlight
                        autoSelect
                        options={zonas}
                        value={selectedZonas}
                        getOptionLabel={(option) =>
                          `${option.codigo}-${option.zona}`
                        }
                        renderOption={(props, option) => (
                          <li key={option.codigo} {...props}>
                            {option.codigo}-{option.zona}
                          </li>
                        )}
                        onChange={(event, value) => {
                          setSelectedZonas(value);
                          setFieldValue("c1Zona1", value ? value.codigo : "");
                          !zonasTipo1.includes(Number(value?.codigo)) &&
                            disabledCuit === false &&
                            value &&
                            handleOpenZona();
                          value &&
                            zonasTipo1.includes(Number(value?.codigo)) &&
                            getCodEmpresa(value.codigo, 1);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={<CustomLabel>Zona</CustomLabel>}
                            error={Boolean(touched.c1Zona1 && errors.c1Zona1)}
                            helperText={touched.c1Zona1 && errors.c1Zona1}
                          />
                        )}
                        id={2}
                        onKeyDown={(e) => handleKeyDown(e, 2)}
                        filterOptions={(options, { inputValue }) => {
                          const inputStr = inputValue.toString();
                          return options.filter((option) =>
                            inputStr === ""
                              ? true
                              : option.codigo.toString() === inputStr
                          );
                        }}
                      />
                    </Grid>
                    <Grid container md={3} sm={3} xs={5}>
                      <TextField
                        disabled={isLoading}
                        fullWidth
                        label={<CustomLabel>Codigo de empresa</CustomLabel>}
                        {...getFieldProps("c1CodEm")}
                        id={3}
                        onKeyDown={(e) => handleKeyDown(e, 3)}
                        error={Boolean(touched.c1CodEm && errors.c1CodEm)}
                        helperText={touched.c1CodEm && errors.c1CodEm}
                      />
                    </Grid>
                    <Grid md={3} sm={3}>
                      <TextField
                        disabled={isLoading}
                        label={<CustomLabel>Razon social</CustomLabel>}
                        fullWidth
                        {...getFieldProps("c1RazSoc")}
                        id={4}
                        onKeyDown={(e) => handleKeyDown(e, 4)}
                      />
                    </Grid>
                    <Grid lg={4} md={3} sm={3}>
                      <TextField
                        disabled={isLoading}
                        label={<CustomLabel>Nombre fantasia</CustomLabel>}
                        fullWidth
                        {...getFieldProps("c1Nom")}
                        id={5}
                        onKeyDown={(e) => handleKeyDown(e, 5)}
                      />
                    </Grid>
                  </Grid>
                </Card>
                <Typography
                  sx={{ marginY: 3 }}
                  textAlign={"center"}
                  variant="h4"
                >
                  Domicilio declarado en AFIP
                </Typography>
                <Card sx={{ padding: 3, marginY: 3 }}>
                  <Grid
                    container
                    spacing={3}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginY: 3,
                    }}
                  >
                    <Grid item md={3} sm={3} xs={9}>
                      <TextField
                        disabled={isLoading}
                        label={<CustomLabel>Calle</CustomLabel>}
                        fullWidth
                        {...getFieldProps("calleAfip")}
                        id={6}
                        onKeyDown={(e) => handleKeyDown(e, 6)}
                      />
                    </Grid>
                    <Grid item md={2} sm={2} xs={9}>
                      <TextField
                        disabled={isLoading}
                        label={<CustomLabel>Numero</CustomLabel>}
                        {...getFieldProps("numeroAfip")}
                        id={7}
                        onKeyDown={(e) => handleKeyDown(e, 7)}
                      />
                    </Grid>

                    <Grid item md={2.5} sm={2.5} xs={9}>
                      <TextField
                        disabled={isLoading}
                        label={<CustomLabel>Piso</CustomLabel>}
                        {...getFieldProps("pisoAfip")}
                        id={8}
                        onKeyDown={(e) => handleKeyDown(e, 8)}
                      />
                    </Grid>

                    <Grid item md={3.5} sm={3.5} xs={9}>
                      <TextField
                        disabled={isLoading}
                        fullWidth
                        error={Boolean(touched.dptoAfip && errors.dptoAfip)}
                        helperText={touched.dptoAfip && errors.dptoAfip}
                        label={<CustomLabel>Depto</CustomLabel>}
                        {...getFieldProps("dptoAfip")}
                        id={9}
                        onKeyDown={(e) => handleKeyDown(e, 9)}
                      />
                    </Grid>

                    <Grid item md={4} sm={4} xs={5}>
                      <Autocomplete
                        disabled={isLoading}
                        disablePortal
                        autoHighlight
                        autoSelect
                        value={localidadAfipSelected}
                        options={localidad}
                        getOptionLabel={(option) =>
                          `${option.codigo}-${option.nombre}`
                        }
                        renderOption={(props, option) => (
                          <li key={option.codigo} {...props}>
                            {option.codigo}-{option.nombre}
                          </li>
                        )}
                        onChange={(event, value) => {
                          setLocalidadAfipSelected(value ? value : null);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={[<CustomLabel>Localidad</CustomLabel>]}
                          />
                        )}
                        id={10}
                        onKeyDown={(e) => handleKeyDown(e, 10)}
                      />
                    </Grid>
                    <Grid item md={4} sm={4} xs={9}>
                      <Autocomplete
                        disabled={isLoading}
                        disablePortal
                        autoHighlight
                        autoSelect
                        value={formik.values.pciaAfip}
                        options={provincias}
                        getOptionLabel={(option) => option.provincia}
                        renderOption={(props, option) => (
                          <li {...props}>{option.provincia}</li>
                        )}
                        onChange={(event, value) => {
                          setFieldValue("pciaAfip", value ? value : "");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={[<CustomLabel>Provincia</CustomLabel>]}
                          />
                        )}
                        id={11}
                        onKeyDown={(e) => handleKeyDown(e, 11)}
                      />
                    </Grid>
                  </Grid>
                </Card>
                <Typography textAlign={"center"} variant="h4">
                  Domicilio declarado en OSPIV
                </Typography>
                <Card sx={{ padding: 3, marginY: 3 }}>
                  <Grid
                    container
                    spacing={3}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginY: 3,
                    }}
                  >
                    <Grid item md={3} sm={3} xs={9}>
                      <TextField
                        disabled={isLoading}
                        fullWidth
                        label={<CustomLabel>Calle</CustomLabel>}
                        {...getFieldProps("c3Calle")}
                        id={12}
                        onKeyDown={(e) => handleKeyDown(e, 12)}
                      />
                    </Grid>

                    <Grid item md={2} sm={2} xs={9}>
                      <TextField
                        disabled={isLoading}
                        fullWidth
                        label={<CustomLabel>Numero</CustomLabel>}
                        {...getFieldProps("c3Num")}
                        id={13}
                        onKeyDown={(e) => handleKeyDown(e, 13)}
                      />
                    </Grid>

                    <Grid item md={2.5} sm={2.5} xs={9}>
                      <TextField
                        disabled={isLoading}
                        fullWidth
                        label={<CustomLabel>Piso</CustomLabel>}
                        {...getFieldProps("c3Piso")}
                        id={14}
                        onKeyDown={(e) => handleKeyDown(e, 14)}
                      />
                    </Grid>

                    <Grid item md={3.5} sm={3.5} xs={9}>
                      <TextField
                        disabled={isLoading}
                        fullWidth
                        label={<CustomLabel>Depto</CustomLabel>}
                        {...getFieldProps("c3Depto")}
                        id={15}
                        onKeyDown={(e) => handleKeyDown(e, 15)}
                      />
                    </Grid>

                    <Grid item md={4} sm={4} xs={10}>
                      <TextField
                        disabled={isLoading}
                        fullWidth
                        label={<CustomLabel>Tel</CustomLabel>}
                        error={Boolean(touched.c3Tel && errors.c3Tel)}
                        helperText={touched.c3Tel && errors.c3Tel}
                        {...getFieldProps("c3Tel")}
                        id={16}
                        onKeyDown={(e) => handleKeyDown(e, 16)}
                      />
                    </Grid>
                    <Grid item md={4} sm={4} xs={4.5}>
                      <Autocomplete
                        disabled={isLoading}
                        disablePortal
                        autoHighlight
                        autoSelect
                        options={localidad}
                        value={selectedCodigo}
                        getOptionLabel={(option) =>
                          `${option.codigo}-${option.nombre}`
                        }
                        renderOption={(props, option) => (
                          <li key={option.codigo} {...props}>
                            {option.codigo}-{option.nombre}
                          </li>
                        )}
                        onChange={(event, value) => {
                          setSelectedCodigo(value);
                          setFieldValue("c3CP", value ? value.codigo : "");
                          setFieldValue("c3Loc", value ? value.nombre : "");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={[<CustomLabel>Localidad</CustomLabel>]}
                          />
                        )}
                        id={17}
                        onKeyDown={(e) => handleKeyDown(e, 17)}
                      />
                    </Grid>
                    <Grid item md={4} sm={4} xs={4}>
                      <Autocomplete
                        disabled={isLoading}
                        disablePortal
                        autoHighlight
                        autoSelect
                        options={provincias}
                        value={selectedProv}
                        getOptionLabel={(option) => option.provincia}
                        renderOption={(props, option) => (
                          <li {...props}>{option.provincia}</li>
                        )}
                        onChange={(event, value) => {
                          setSelectedProv(value);
                          setFieldValue("c3Prov", value ? value.codigo : "");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={[<CustomLabel>Provincia</CustomLabel>]}
                          />
                        )}
                        id={18}
                        onKeyDown={(e) => handleKeyDown(e, 18)}
                      />
                    </Grid>
                  </Grid>
                </Card>
                {/* <Typography textAlign={"center"} variant="h4">
                  Domicilio de taller
                </Typography>
                <Card sx={{ padding: 3, marginY: 3 }}>
                  <Grid
                    container
                    spacing={3}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginY: 3,
                    }}
                  >
                    <Grid item md={3} sm={3} xs={9}>
                      <TextField
                        label={<CustomLabel>Calle</CustomLabel>}
                        fullWidth
                        {...getFieldProps("c4Calle")}
                        id={22}
                        onKeyDown={(e) => handleKeyDown(e, 22)}
                      />
                    </Grid>
                    <Grid item md={2} sm={1} xs={9}>
                      <TextField
                        label={<CustomLabel>Numero</CustomLabel>}
                        {...getFieldProps("c4Num")}
                        id={23}
                        onKeyDown={(e) => handleKeyDown(e, 23)}
                      />
                    </Grid>
                    <Grid item md={2.5} sm={1} xs={9}>
                      <TextField
                        fullWidth
                        label={<CustomLabel>Piso</CustomLabel>}
                        {...getFieldProps("c4Piso")}
                        id={24}
                        onKeyDown={(e) => handleKeyDown(e, 24)}
                      />
                    </Grid>
                    <Grid item md={3.5} sm={1} xs={9}>
                      <TextField
                        fullWidth
                        label={<CustomLabel>Depto</CustomLabel>}
                        {...getFieldProps("c4Depto")}
                        id={25}
                        onKeyDown={(e) => handleKeyDown(e, 25)}
                      />
                    </Grid>
                    <Grid item md={2.4} sm={2} xs={10}>
                      <TextField
                        label={<CustomLabel>Tel</CustomLabel>}
                        {...getFieldProps("c4Tel")}
                        id={26}
                        onKeyDown={(e) => handleKeyDown(e, 26)}
                      />
                    </Grid>
                    <Grid item md={2.4} sm={1} xs={4.5}>
                      <TextField
                        label={<CustomLabel>C.P</CustomLabel>}
                        {...getFieldProps("c4CP")}
                        id={27}
                        onKeyDown={(e) => handleKeyDown(e, 27)}
                      />
                    </Grid>
                    <Grid item md={2.4} sm={1} xs={4.5}>
                      <TextField
                        label=""
                        {...getFieldProps("c4CP2")}
                        id={28}
                        onKeyDown={(e) => handleKeyDown(e, 28)}
                      />
                    </Grid>
                    <Grid item md={2.4} sm={2} xs={4}>
                      <Autocomplete
                        disabled={isLoading}
                        disablePortal
                        autoHighlight
                        autoSelect
                        options={provincias}
                        getOptionLabel={(option) => option.provincia}
                        renderOption={(props, option) => (
                          <li {...props}>{option.provincia}</li>
                        )}
                        onChange={(event, value) => {
                          setFieldValue("c4Prov", value ? value.codigo : "");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={[<CustomLabel>Provincia</CustomLabel>]}
                            error={Boolean(touched.c4Prov && errors.c4Prov)}
                            helperText={touched.c4Prov && errors.c4Prov}
                          />
                        )}
                        id={29}
                        onKeyDown={(e) => handleKeyDown(e, 29)}
                      />
                    </Grid>
                  </Grid>
                </Card> */}
                <Card sx={{ padding: 3, marginY: 3 }}>
                  <Grid
                    container
                    spacing={3}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginY: 3,
                    }}
                  >
                    <Grid item md={4} sm={4} xs={8}>
                      <DatePicker
                        disabled={isLoading}
                        sx={{ width: "100%" }}
                        fullWidth
                        label={<CustomLabel>Fecha de alta</CustomLabel>}
                        value={feAlta}
                        format="dd-MM-yyyy"
                        onChange={(date) => {
                          setFeAlta(date);
                        }}
                        onAccept={() => {
                          setTimeout(() => {
                            document.getElementById(31).focus();
                          }, "50");
                        }}
                        inputRef={datePickerRef}
                        //30
                      />
                    </Grid>

                    <Grid item md={4} sm={4} xs={4}>
                      <Autocomplete
                        fullWidth
                        disabled={isLoading}
                        disablePortal
                        autoHighlight
                        autoSelect
                        options={especialidades}
                        value={selectedEspecialidad}
                        getOptionLabel={(option) => `${option.label}`}
                        renderOption={(props, option) => (
                          <li key={option.codigo} {...props}>
                            {option.label}
                          </li>
                        )}
                        onChange={(event, value) => {
                          setSelectedEspecialidad(value);
                          setFieldValue("c5Espe", value ? value.id : "");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={[<CustomLabel>Especialidad</CustomLabel>]}
                          />
                        )}
                        id={31}
                        onKeyDown={(e) => handleKeyDown(e, 31)}
                      />
                    </Grid>
                    <Grid item md={4} sm={4} xs={8}>
                      <DatePicker
                        disabled={isLoading}
                        sx={{ width: "100%" }}
                        label={<CustomLabel>Fecha de baja</CustomLabel>}
                        format="dd-MM-yyyy"
                        value={feBaja}
                        onChange={(date) => {
                          setFeBaja(date);
                        }}
                        onAccept={() => {
                          setTimeout(() => {
                            document.getElementById(33).focus();
                          }, "50");
                        }}
                        inputRef={datePickerRef2}
                        //32
                      />
                    </Grid>
                    <Grid item md={4} sm={4} xs={8}>
                      <TextField
                        disabled={isLoading}
                        label={<CustomLabel>Motivo de baja</CustomLabel>}
                        fullWidth
                        {...getFieldProps("c5MotBaj")}
                        id={33}
                        onKeyDown={(e) => handleKeyDown(e, 33)}
                      />
                    </Grid>
                  </Grid>
                </Card>
                <Grid md={12} textAlign={"center"}>
                  <Typography variant="h4">
                    Información suministrada por AFIP
                  </Typography>
                </Grid>
                <Card sx={{ padding: 3, marginY: 3 }}>
                  <Grid container spacing={3} sm={12}>
                    <Grid container spacing={3} md={6} sm={6} sx={{ p: 3 }}>
                      <Grid item md={12}>
                        <Typography textAlign={"center"} variant="subtitle1">
                          Ultimos periodos informados
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <Typography
                          marginY={"auto"}
                          variant="h4"
                          sx={{ color: " #376fd0", fontSize: "15px", mb: 1 }}
                        >
                          Declaraciones juradas:
                        </Typography>
                        <TextField
                          disabled
                          label=""
                          {...getFieldProps("c6DdJj")}
                          // id={34}
                          // onKeyDown={(e) => handleKeyDown(e, 34)}
                        />
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <Typography
                          marginY={"auto"}
                          variant="h4"
                          sx={{ color: " #376fd0", fontSize: "15px", mb: 1 }}
                        >
                          Reducción de aportes:
                        </Typography>
                        <TextField
                          disabled
                          label=""
                          {...getFieldProps("c5RedApo")}
                          // id={35}
                          // onKeyDown={(e) => handleKeyDown(e, 35)}
                        />
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <Typography
                          marginY={"auto"}
                          variant="h4"
                          sx={{ color: " #376fd0", fontSize: "15px", mb: 1 }}
                        >
                          Pago:
                        </Typography>
                        <TextField
                          disabled
                          label=""
                          {...getFieldProps("c6Pago")}
                          // id={37}
                          // onKeyDown={(e) => handleKeyDown(e, 37)}
                        />
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <Typography
                          marginY={"auto"}
                          variant="h4"
                          sx={{ color: " #376fd0", fontSize: "15px", mb: 1 }}
                        >
                          Transferencias:
                        </Typography>
                        <TextField
                          disabled
                          label=""
                          {...getFieldProps("c5Tran")}
                          // id={38}
                          // onKeyDown={(e) => handleKeyDown(e, 38)}
                        />
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <Typography
                          marginY={"auto"}
                          variant="h4"
                          sx={{ color: " #376fd0", fontSize: "15px", mb: 1 }}
                        >
                          Subsidio:
                        </Typography>
                        <TextField
                          disabled
                          label=""
                          {...getFieldProps("c6Subs")}
                          // id={39}
                          // onKeyDown={(e) => handleKeyDown(e, 39)}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} md={6} sm={6} sx={{ p: 3 }}>
                      <Grid item md={12} sm={12}>
                        <Typography
                          variant="subtitle1"
                          textAlign={"center"}
                          sx={{ width: "100%", height: "26px" }}
                        >
                          Cantidad de obreros
                        </Typography>
                        <Box sx={{ display: "flex", gap: 5, mt: 8 }}>
                          <TextField
                            disabled={isLoading}
                            label={<CustomLabel>OSPIV</CustomLabel>}
                            {...getFieldProps("c6Ospiv")}
                            id={34}
                            onKeyDown={(e) => handleKeyDown(e, 34)}
                          />
                          <TextField
                            disabled={isLoading}
                            label={<CustomLabel>AFIP</CustomLabel>}
                            {...getFieldProps("c6Afip")}
                            id={35}
                            onKeyDown={(e) => handleKeyDown(e, 35)}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
            <Box
              sx={{ display: "flex", justifyContent: "center", my: 4, gap: 3 }}
            >
              <LoadingButton
                sx={{ height: "100%" }}
                loading={isLoading}
                onClick={formik.handleSubmit}
                variant="contained"
              >
                Guardar
              </LoadingButton>
              <Button variant="contained" color="error" onClick={resetForm}>
                Cancelar
              </Button>
            </Box>
          </LocalizationProvider>
          {/* -------------------------------- Dialogo zona -------------------------------------- */}
          <DialogZona
            setKeyZona={setKeyZona}
            handleOpen={handleOpenZona}
            open={openDialogZona}
          />
          <Dialog
            open={openDialog}
            fullWidth
            maxWidth={"sm"}
            keepMounted
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle
              sx={{ display: "flex", alignItems: "center", color: "#ffb300" }}
            >
              <WarningIcon sx={{ fontSize: 30, marginRight: 1.5 }} /> ¿Proceder
              con la baja de la empresa y los afiliados de la misma?
            </DialogTitle>
            <DialogContent>
              Este proceso dara de baja la empresa y todos sus afiliados
              asociados. ¿Estás seguro de que deseas continuar?
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  formik.handleSubmit();
                  handleCloseDialog();
                }}
                color="error"
              >
                Confirmar Baja
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
      </FormikProvider>

      {openDialogMultiEmpresa && (
        <DialogMultiEmpresa
          open={openDialogMultiEmpresa}
          onClose={handleCloseDialogMultiEmpresa}
          fetchEmpresa={handleChangeEmpresa}
          cuit={datosEmpresa}
        />
      )}
    </>
  );
}
