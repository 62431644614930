import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
//import PresentationLayout from "./layouts/Presentation";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import PageInDevelopment from "./pages/auth/PageInDevelopment";
import Page500 from "./pages/auth/Page500";
import NotPrivil from "./pages/auth/NotPrivil";
import RoleBasedGuard from "./guards/RoleBasedGuard";
import ABMBeneficiarios from "./pages/Empadronamiento/beneficiarios/ABM";
// import Reportes from "./pages/Empadronamiento/beneficiarios/Reportes";
import ABMEmpresas from "./pages/Empadronamiento/empresas/ABM";
import ReportesEmpresas from "./pages/Empadronamiento/empresas/Reportes";
import ArchivosAfip from "./pages/Empadronamiento/ArchivosAFIP";
// import ReporteBeneficiarios from "./components/Empadronamiento/Beneficiarios/Reporte";
//-- reportes
import ReportesDeBeneficiarios from "./components/Empadronamiento/Reportes";
import BeneficiariosEntreNum from "./pages/Empadronamiento/beneficiarios/reportes/BeneficiariosEntreNum";
import ConsultaPorFamiliares from "./pages/Empadronamiento/beneficiarios/reportes/ConsultaPorFamiliares";
import Monotributistas from "./pages/Empadronamiento/beneficiarios/reportes/Monotributistas";
import MovimientosBenef from "./pages/Empadronamiento/beneficiarios/reportes/MovimientosBenef";
import NominaDeExtranjeros from "./pages/Empadronamiento/beneficiarios/reportes/NominaDeExtranjeros";
import Padron from "./pages/Empadronamiento/beneficiarios/reportes/Padron";
import PersonalBajaAlta from "./pages/Empadronamiento/beneficiarios/reportes/PersonalBajaAlta";
import Poblacion from "./pages/Empadronamiento/beneficiarios/reportes/Poblacion";
import ResumenTitulares from "./pages/Empadronamiento/beneficiarios/reportes/ResumenTitulares";
import TotalTitulares from "./pages/Empadronamiento/beneficiarios/reportes/TotalTitulares";
import TransBenef from "./pages/Empadronamiento/beneficiarios/reportes/TransBenef";
import TransfYReduc from "./pages/Empadronamiento/beneficiarios/reportes/TransYReduc";
import TotalesPropAdeMono from "./pages/Empadronamiento/beneficiarios/reportes/TotalesAdh";
import MonotributistasSinPagos from "./pages/Empadronamiento/beneficiarios/reportes/MonotributistasSinPagos";
import VerUsuario from "./pages/Administracion/VerUsuarios";
import Gerencia from "./pages/Gerencia/Gerencia";
import BenefPorProvincia from "./pages/Empadronamiento/beneficiarios/reportes/BenefPorProvincia";
import DiscapacitadosPorProvicia from "./pages/Empadronamiento/beneficiarios/reportes/DiscapacitadosPorProvicia";
import PedidoAnticonceptivos from "./pages/Empadronamiento/beneficiarios/reportes/PedidoAnticonceptivos";
import PrestadoresPorFecha from "./components/ActaVirtual/Auditoria/PrestadoresPorFecha";
import PracticasSolicitadas from "./components/ActaVirtual/Auditoria/PracticasSolicitadas";
//---- reportes empresas
import Reporte from "./components/Empadronamiento/Empresas/Reporte";
import ComparativaEntreDeclaYPag from "./pages/Empadronamiento/empresas/reportes/ComparativaEntreDeclaYPag";
import ConPresentacionDdjj from "./pages/Empadronamiento/empresas/reportes/ConPresentacionDdjj";
import ConPresentacionDePagos from "./pages/Empadronamiento/empresas/reportes/ConPresentacionDePagos";
import SinPresentacionDdjj from "./pages/Empadronamiento/empresas/reportes/SinPresentacionDdjj";
import Afiliaciones from "./pages/Empadronamiento/empresas/Afiliaciones";

//---- Reporte PMI
import Aprobados from "./components/Liquidaciones/Reportes/Aprobados";
import Rechazados from "./components/Liquidaciones/Reportes/Rechazados";
import Pendientes from "./components/Liquidaciones/Reportes/Pendientes";

//---- Cobranza

// Dashboard components
const Home = async(() => import("./pages/Home"));
const Empadronamiento = async(() =>
  import("./pages/Empadronamiento/Empadronamiento")
);
const Consulta = async(() => import("./pages/Empadronamiento/Consulta"));
const PrePrestacional = async(() =>
  import("./pages/PrePrestacional/PrePrestacional")
);
const AuditoriaDeSolicitudes = async(() =>
  import("./pages/PrePrestacional/AuditoriaDeSolicitudes")
);
const Ingreso = async(() => import("./pages/PrePrestacional/Ingreso"));
const GestionRecupero = async(() => import("./pages/Cobranza/GestionRecupero"));
const ChequeRechazado = async(() => import("./pages/Cobranza/ChequeRechazado"));
const CargaDeActas = async(() => import("./pages/Cobranza/CargaDeActas"));
const VerJudiciales = async(() => import("./pages/Cobranza/VerJudiciales"));
const Recibos = async(() => import("./pages/Cobranza/Recibos"));
const ModificarRecibos = async(() =>
  import("./pages/Cobranza/ModificarRecibos")
);
const Cobranza = async(() => import("./pages/Cobranza/Cobranza"));
const RendicionDeCuentas = async(() =>
  import("./pages/Cobranza/reportes/RendicionDeCuentas")
);
const DarVueltaActa = async(() => import("./pages/Cobranza/DarVueltaActa"));
const CuentaCorrienteEmp = async(() =>
  import("./pages/Cobranza/CuentaCorrienteEmp")
);
const ReporteDeDeudas = async(() =>
  import("./pages/Cobranza/reportes/ReporteDeDeudas")
);

const ReporteRecibos = async(() => import("./pages/Cobranza/reportes/Recibos"));
const ReporteCheques = async(() => import("./pages/Cobranza/reportes/Cheques"));
const ReporteActas = async(() => import("./pages/Cobranza/reportes/Actas"));

const CargaDeConvenios = async(() =>
  import("./pages/Cobranza/CargaDeConvenios")
);
const VerDetalle = async(() => import("./pages/Cobranza/VerDetalle"));
//const ActaVirtual = async(() => import("./pages/Cobranza/ActaVirtual"));
const BuscarEmpresaAV = async(() =>
  import("./pages/ActaVirtual/BuscarEmpresaAV")
);
const Auditoria = async(() => import("./pages/ActaVirtual/Auditoria"));
const Administracion = async(() =>
  import("./pages/Administracion/Administracion")
);
const Privilegios = async(() => import("./pages/Administracion/Privilegios"));
const TableroDeControl = async(() =>
  import("./pages/Gerencia/TableroDeControl")
);
const Perfil = async(() => import("./pages/pages/Perfil"));
const NumEmpresa = async(() => import("./pages/Empadronamiento/NumEmp"));
const BenefReduc = async(() => import("./pages/Empadronamiento/BenefReduc"));
const EmpReduc = async(() => import("./pages/Empadronamiento/EmpReduc"));
const EmpTransfer = async(() => import("./pages/Empadronamiento/EmpTransfer"));
const BenefTransfer = async(() =>
  import("./pages/Empadronamiento/BenefTransfer")
);
const Jubilados = async(() =>
  import("./pages/Empadronamiento/beneficiarios/reportes/Jubilados")
);
const CambioNBenef = async(() =>
  import("./pages/Empadronamiento/CambioNBenef")
);
const Cumpleanos = async(() => import("./pages/Empadronamiento/Cumpleaños"));
const Discapacidad = async(() =>
  import("./pages/Empadronamiento/Discapacidad")
);
const ABMNyL = async(() => import("./pages/Empadronamiento/NaciosYLocalis"));
const PresentarNovedades = async(() =>
  import("./pages/Empadronamiento/PresentarNovedades")
);
const ReporteArchAFIP = async(() => import("./pages/Empadronamiento/Reportes"));

const ReportesContaduria = async(() => import("./pages/Contaduria/Reportes"));
const MorososReport = async(() =>
  import("./pages/Contaduria/Reports/MorososReport")
);
const SubsidiosReport = async(() =>
  import("./pages/Contaduria/Reports/SubsidiosReport")
);
const DeudaEmpresasReport = async(() =>
  import("./pages/Contaduria/Reports/DeudaEmpresasReport")
);
const ReporteDeCreditos = async(() =>
  import("./pages/Contaduria/Reports/ReporteDeCreditos")
);
const Report931 = async(() => import("./pages/Contaduria/Reports/931Report"));

const Autorizaciones = async(() =>
  import("./pages/practicasHospital/Autorizaciones")
);

const ABMPracticasHospital = async(() =>
  import("./pages/practicasHospital/ABM")
);

const CargaPagoACuenta = async(() => import("./pages/Cobranza/PagosACuenta"));
const CargaPagoDeConvenios = async(() =>
  import("./pages/Cobranza/PagoDeConvenios")
);

const Liquidaciones = async(() =>
  import("./pages/Liquidaciones/Liquidaciones")
);
const PMI = async(() => import("./pages/Liquidaciones/PMI"));
const ReportesPMI = async(() => import("./pages/Liquidaciones/Reportes"));
const Bazterrica = async(() =>
  import("./pages/Empadronamiento/Bazterrica/Bazterrica")
);

const routes = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "/",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
      {
        path: "development",
        element: <PageInDevelopment />,
      },
      {
        path: "cambiarContraseña",
        element: <ResetPassword />,
      },
    ],
  },

  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "NotPrivil",
        element: <NotPrivil />,
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "empadronamiento",
        children: [
          {
            path: "",
            element: <Empadronamiento />,
          },
          {
            path: "consulta",
            element: (
              <RoleBasedGuard accessibleRoles={["301"]}>
                <Consulta />
              </RoleBasedGuard>
            ),
          },
          {
            path: "consultaNumeroZonaEmpresa",
            element: (
              <RoleBasedGuard accessibleRoles={["307"]}>
                <NumEmpresa />
              </RoleBasedGuard>
            ),
          },
          {
            path: "reducBenef",
            element: (
              <RoleBasedGuard accessibleRoles={["308"]}>
                <BenefReduc />
              </RoleBasedGuard>
            ),
          },
          {
            path: "reducEmp",
            element: (
              <RoleBasedGuard accessibleRoles={["309"]}>
                <EmpReduc />
              </RoleBasedGuard>
            ),
          },
          {
            path: "transferEmp",
            element: (
              <RoleBasedGuard accessibleRoles={["309"]}>
                <EmpTransfer />
              </RoleBasedGuard>
            ),
          },
          {
            path: "benefEmp",
            element: (
              <RoleBasedGuard accessibleRoles={["309"]}>
                <BenefTransfer />
              </RoleBasedGuard>
            ),
          },
          {
            path: "cumpleanos",
            element: (
              <RoleBasedGuard accessibleRoles={["310"]}>
                <Cumpleanos />
              </RoleBasedGuard>
            ),
          },
          {
            path: "discapacidad",
            element: (
              <RoleBasedGuard accessibleRoles={["311"]}>
                <Discapacidad />
              </RoleBasedGuard>
            ),
          },
          {
            path: "nacionalidadesYLocalidades",
            element: (
              <RoleBasedGuard accessibleRoles={["312"]}>
                <ABMNyL />
              </RoleBasedGuard>
            ),
          },
          {
            path: "presentarNovedades",
            element: (
              <RoleBasedGuard accessibleRoles={["313"]}>
                <PresentarNovedades />
              </RoleBasedGuard>
            ),
          },
          {
            path: "cambioNBenef",
            element: (
              <RoleBasedGuard accessibleRoles={["316"]}>
                <CambioNBenef />
              </RoleBasedGuard>
            ),
          },
          {
            path: "bazterrica",
            element: (
              <RoleBasedGuard accessibleRoles={["318"]}>
                <Bazterrica />
              </RoleBasedGuard>
            ),
          },
          {
            path: "beneficiarios",
            children: [
              {
                path: "abm",
                element: (
                  <RoleBasedGuard accessibleRoles={["302"]}>
                    <ABMBeneficiarios />
                  </RoleBasedGuard>
                ),
              },
              {
                path: "reportebenf",
                children: [
                  {
                    path: "",
                    element: (
                      <RoleBasedGuard accessibleRoles={["303"]}>
                        <ReportesDeBeneficiarios />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: "padron",
                    element: (
                      <RoleBasedGuard accessibleRoles={["303"]}>
                        <Padron />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: "consultaPorFamiliares",
                    element: (
                      <RoleBasedGuard accessibleRoles={["303"]}>
                        <ConsultaPorFamiliares />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: "totalTitulares",
                    element: (
                      <RoleBasedGuard accessibleRoles={["303"]}>
                        <TotalTitulares />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: "poblacion",
                    element: (
                      <RoleBasedGuard accessibleRoles={["303"]}>
                        <Poblacion />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: "benefEntreNumeroYDeclaracion",
                    element: (
                      <RoleBasedGuard accessibleRoles={["303"]}>
                        <BeneficiariosEntreNum />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: "monotributistas",
                    element: (
                      <RoleBasedGuard accessibleRoles={["303"]}>
                        <Monotributistas />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: "benefEntreTransYReduc",
                    element: (
                      <RoleBasedGuard accessibleRoles={["303"]}>
                        <TransfYReduc />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: "transferenciaBeneficiarios",
                    element: (
                      <RoleBasedGuard accessibleRoles={["303"]}>
                        <TransBenef />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: "totales",
                    element: (
                      <RoleBasedGuard accessibleRoles={["303"]}>
                        <TotalesPropAdeMono />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: "movimientos",
                    element: (
                      <RoleBasedGuard accessibleRoles={["303"]}>
                        <MovimientosBenef />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: "nominaDeExtranjeros",
                    element: (
                      <RoleBasedGuard accessibleRoles={["303"]}>
                        <NominaDeExtranjeros />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: "personalDeBajaAlta",
                    element: (
                      <RoleBasedGuard accessibleRoles={["303"]}>
                        <PersonalBajaAlta />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: "resumenDeTitulares",
                    element: (
                      <RoleBasedGuard accessibleRoles={["303"]}>
                        <ResumenTitulares />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: "monotributistasSinPagos",
                    element: (
                      <RoleBasedGuard accessibleRoles={["303"]}>
                        <MonotributistasSinPagos />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: "jubilados",
                    element: (
                      <RoleBasedGuard accessibleRoles={["303"]}>
                        <Jubilados />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: "benefPorProvincia",
                    element: (
                      <RoleBasedGuard accessibleRoles={["303"]}>
                        <BenefPorProvincia />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: "discapacitadosPorProvincia",
                    element: (
                      <RoleBasedGuard accessibleRoles={["303"]}>
                        <DiscapacitadosPorProvicia />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: "pedidoAnticonceptivos",
                    element: (
                      <RoleBasedGuard accessibleRoles={["303"]}>
                        <PedidoAnticonceptivos />
                      </RoleBasedGuard>
                    ),
                  },
                ],
              },
              {
                path: "reportes/reporte/transferencias y reducciones",
                element: (
                  <RoleBasedGuard accessibleRoles={["303"]}>
                    <TransfYReduc />
                  </RoleBasedGuard>
                ),
              },
            ],
          },
          {
            path: "empresas",
            children: [
              {
                path: "abm",
                element: (
                  <RoleBasedGuard accessibleRoles={["304"]}>
                    <ABMEmpresas />
                  </RoleBasedGuard>
                ),
              },
              {
                path: "afiliaciones",
                element: (
                  <RoleBasedGuard accessibleRoles={["305"]}>
                    <Afiliaciones />
                  </RoleBasedGuard>
                ),
              },
              {
                path: "reportes",
                children: [
                  {
                    path: "",
                    element: (
                      <RoleBasedGuard accessibleRoles={["305"]}>
                        <ReportesEmpresas />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: "empresasQuePresentaronDdjj",
                    element: (
                      <RoleBasedGuard accessibleRoles={["305"]}>
                        <ConPresentacionDdjj />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: "empresasQueNoPresentaronDdjj",
                    element: (
                      <RoleBasedGuard accessibleRoles={["305"]}>
                        <SinPresentacionDdjj />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: "empresasQuePresentaronPagos",
                    element: (
                      <RoleBasedGuard accessibleRoles={["305"]}>
                        <ConPresentacionDePagos />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: "comparativaEntreDeclaradoYPagado",
                    element: (
                      <RoleBasedGuard accessibleRoles={["305"]}>
                        <ComparativaEntreDeclaYPag />
                      </RoleBasedGuard>
                    ),
                  },
                ],
              },
              {
                path: "reportes/reporte/:reporteID",
                element: (
                  <RoleBasedGuard accessibleRoles={["305"]}>
                    <Reporte />
                  </RoleBasedGuard>
                ),
              },
            ],
          },
          {
            path: "archivos-afip",
            children: [
              {
                path: "",
                element: (
                  <RoleBasedGuard accessibleRoles={["306"]}>
                    <ArchivosAfip />
                  </RoleBasedGuard>
                ),
              },
              {
                path: "reporte",
                element: (
                  <RoleBasedGuard accessibleRoles={["306"]}>
                    <ReporteArchAFIP />
                  </RoleBasedGuard>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "pre-prestacional",
        children: [
          {
            path: "",
            element: <PrePrestacional />,
          },
          {
            path: "ingreso",
            element: (
              <RoleBasedGuard accessibleRoles={["401"]}>
                <Ingreso />
              </RoleBasedGuard>
            ),
          },
          {
            path: "auditoriaDeSolicitudes",
            element: (
              <RoleBasedGuard accessibleRoles={["402"]}>
                <AuditoriaDeSolicitudes />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "actaVirtual",
        children: [
          {
            path: "",
            element: (
              <RoleBasedGuard accessibleRoles={["201"]}>
                <BuscarEmpresaAV />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "auditoria",
        children: [
          {
            path: "",
            element: (
              <RoleBasedGuard accessibleRoles={["403"]}>
                <Auditoria />
              </RoleBasedGuard>
            ),
          },
          {
            path: "prestadoresPorFecha",
            element: (
              <RoleBasedGuard accessibleRoles={["403"]}>
                <PrestadoresPorFecha />
              </RoleBasedGuard>
            ),
          },
          {
            path: "practicasSolicitadas",
            element: (
              <RoleBasedGuard accessibleRoles={["403"]}>
                <PracticasSolicitadas />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "cobranza",
        children: [
          {
            path: "",
            element: <Cobranza />,
          },
          {
            path: "verJudiciales",
            element: (
              <RoleBasedGuard accessibleRoles={["505"]}>
                <VerJudiciales />
              </RoleBasedGuard>
            ),
          },
          {
            path: "recibos",
            element: (
              <RoleBasedGuard accessibleRoles={["507"]}>
                <Recibos />
              </RoleBasedGuard>
            ),
          },
          {
            path: "modificarRecibos",
            element: (
              <RoleBasedGuard accessibleRoles={["515"]}>
                <ModificarRecibos />
              </RoleBasedGuard>
            ),
          },
          {
            path: "chequeRechazado",
            element: (
              <RoleBasedGuard accessibleRoles={["506"]}>
                <ChequeRechazado />
              </RoleBasedGuard>
            ),
          },
          {
            path: "cuentaCorriente",
            element: (
              <RoleBasedGuard accessibleRoles={["506"]}>
                <CuentaCorrienteEmp />
              </RoleBasedGuard>
            ),
          },
          {
            path: "pagosACuenta",
            element: (
              <RoleBasedGuard accessibleRoles={["513"]}>
                <CargaPagoACuenta />
              </RoleBasedGuard>
            ),
          },
          {
            path: "pagosDeConvenios",
            element: (
              <RoleBasedGuard accessibleRoles={["513"]}>
                <CargaPagoDeConvenios />
              </RoleBasedGuard>
            ),
          },
          {
            path: "gestionRecupero",
            children: [
              {
                path: "",
                element: (
                  <RoleBasedGuard accessibleRoles={["501"]}>
                    <GestionRecupero />
                  </RoleBasedGuard>
                ),
              },
              {
                path: ":cuit/verDetalle",
                element: (
                  <RoleBasedGuard accessibleRoles={["502"]}>
                    <VerDetalle />
                  </RoleBasedGuard>
                ),
              },
              // {
              //   path: ":cuit/actaVirtual",
              //   element: (
              //     <RoleBasedGuard accessibleRoles={["503"]}>
              //       <ActaVirtual />
              //     </RoleBasedGuard>
              //   ),
              // },
            ],
          },
          {
            path: "cargaDeActas",
            children: [
              {
                path: "",
                element: (
                  <RoleBasedGuard accessibleRoles={["504"]}>
                    <CargaDeActas />
                  </RoleBasedGuard>
                ),
              },
            ],
          },
          {
            path: "cargaDeConvenios",
            children: [
              {
                path: "",
                element: (
                  <RoleBasedGuard accessibleRoles={["509"]}>
                    <CargaDeConvenios />
                  </RoleBasedGuard>
                ),
              },
            ],
          },
          {
            path: "darVueltaActa",
            element: (
              <RoleBasedGuard accessibleRoles={["512"]}>
                <DarVueltaActa />
              </RoleBasedGuard>
            ),
          },
          {
            path: "reportes/rendicion",
            element: (
              <RoleBasedGuard accessibleRoles={["508"]}>
                <RendicionDeCuentas />
              </RoleBasedGuard>
            ),
          },
          {
            path: "reportes/recibos",
            element: (
              <RoleBasedGuard accessibleRoles={["508"]}>
                <ReporteRecibos />
              </RoleBasedGuard>
            ),
          },
          {
            path: "reportes/cheques",
            element: (
              <RoleBasedGuard accessibleRoles={["508"]}>
                <ReporteCheques />
              </RoleBasedGuard>
            ),
          },
          {
            path: "reportes/actas",
            element: (
              <RoleBasedGuard accessibleRoles={["508"]}>
                <ReporteActas />
              </RoleBasedGuard>
            ),
          },
          {
            path: "reportes/deudas",
            element: (
              <RoleBasedGuard accessibleRoles={["508"]}>
                <ReporteDeDeudas />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "contaduria",
        children: [
          {
            path: "reportes",
            children: [
              {
                path: "",
                element: (
                  <RoleBasedGuard accessibleRoles={["601"]}>
                    <ReportesContaduria />
                  </RoleBasedGuard>
                ),
              },
              {
                path: "morosos",
                element: (
                  <RoleBasedGuard accessibleRoles={["601"]}>
                    <MorososReport />
                  </RoleBasedGuard>
                ),
              },
              {
                path: "subsidios",
                element: (
                  <RoleBasedGuard accessibleRoles={["601"]}>
                    <SubsidiosReport />
                  </RoleBasedGuard>
                ),
              },
              {
                path: "deudaEmpresas",
                element: (
                  <RoleBasedGuard accessibleRoles={["601"]}>
                    <DeudaEmpresasReport />
                  </RoleBasedGuard>
                ),
              },
              {
                path: "931",
                element: (
                  <RoleBasedGuard accessibleRoles={["601"]}>
                    <Report931 />
                  </RoleBasedGuard>
                ),
              },
              {
                path: "creditos",
                element: (
                  <RoleBasedGuard accessibleRoles={["601"]}>
                    <ReporteDeCreditos />
                  </RoleBasedGuard>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "administracion",
        children: [
          {
            path: "",
            element: (
              <RoleBasedGuard accessibleRoles={["801"]}>
                <Administracion />
              </RoleBasedGuard>
            ),
          },
          {
            path: "privilegios",
            element: (
              <RoleBasedGuard accessibleRoles={["801"]}>
                <Privilegios />
              </RoleBasedGuard>
            ),
          },
          {
            path: "verUsuarios",
            element: (
              <RoleBasedGuard accessibleRoles={["802"]}>
                <VerUsuario />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "gerencia",
        children: [
          {
            path: "",
            element: (
              <RoleBasedGuard accessibleRoles={["901"]}>
                <Gerencia />
              </RoleBasedGuard>
            ),
          },
          {
            path: "tableroControl",
            element: (
              <RoleBasedGuard accessibleRoles={["901"]}>
                <TableroDeControl />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "practicashospital",
        children: [
          {
            path: "abm",
            element: (
              <RoleBasedGuard accessibleRoles={["801"]}>
                <ABMPracticasHospital />
              </RoleBasedGuard>
            ),
          },
          {
            path: "autorizacion",
            element: (
              <RoleBasedGuard accessibleRoles={["802"]}>
                <Autorizaciones />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "liquidaciones",
        children: [
          {
            path: "",
            element: (
              <RoleBasedGuard accessibleRoles={["101"]}>
                <Liquidaciones />
              </RoleBasedGuard>
            ),
          },
          {
            path: "pmi",
            element: (
              <RoleBasedGuard accessibleRoles={["101"]}>
                <PMI />
              </RoleBasedGuard>
            ),
          },
          {
            path: "reportes",
            children: [
              {
                path: "",
                element: (
                  <RoleBasedGuard accessibleRoles={["101"]}>
                    <ReportesPMI />
                  </RoleBasedGuard>
                ),
              },
              {
                path: "pendientes",
                element: (
                  <RoleBasedGuard accessibleRoles={["101"]}>
                    <Pendientes />
                  </RoleBasedGuard>
                ),
              },
              {
                path: "rechazados",
                element: (
                  <RoleBasedGuard accessibleRoles={["101"]}>
                    <Rechazados />
                  </RoleBasedGuard>
                ),
              },
              {
                path: "aprobados",
                element: (
                  <RoleBasedGuard accessibleRoles={["101"]}>
                    <Aprobados />
                  </RoleBasedGuard>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "perfil",
        element: <Perfil />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
